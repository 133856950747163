import React, { useMemo } from 'react'
import { daysOfWeek } from '../../../constants'
import { useTimesheetContextV2 } from '../../../contexts/timesheetContextV2'
import { useTimeTableApiV2, useTimeTableContextV2 } from '../../../contexts/timeTableContextV2'
import { tableStyles } from '../../../utilities/stylesHelpers'
import { projectManagerOrGreater } from '../../../utilities/userHelpers'
import { notifyAlert } from "../../shared/notice"

const TimeTableEntryRow = ({ entry, entryDay }) => {
  const { tasks, projects, user } = useTimesheetContextV2()
  const { initEditModal } = useTimeTableApiV2()
  const { week } = useTimeTableContextV2()
  const task = useMemo(() => tasks.find(task => task.id === entry.timesheet_task_id), [tasks, entry])
  const project = useMemo(() => projects[entry.project_id], [projects, entry])
  const lockingConditions = week[entryDay].approved && !projectManagerOrGreater(user)
  const tdStyle = lockingConditions? tableStyles.tdApproved : tableStyles.td

  const onClick = () => {
    lockingConditions ? notifyAlert(`${entryDay.charAt(0).toUpperCase() + entryDay.slice(1)} has been approved and cannot be edited! Talk to your Project Manager if you need to make changes.`) :
      initEditModal({ ...entry, portfolio_id: project.portfolio_id, user_id: week.user_id, selectedDay: entryDay, day_entry_id: week[entryDay].id })
  }
  return (
    <tr onClick={onClick}>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.job_number}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.historical_project_number}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project?.owner_organization_name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project?.service_organization_name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{task.name}</p>
      </td>
      {daysOfWeek.map((day) => (
        <td className={tdStyle} key={day}>
          {entryDay === day ? entry.hours : null}
        </td>
      ))}
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{entry.description}</p>
      </td>
    </tr>
  )
}

export default TimeTableEntryRow
