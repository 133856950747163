import React, { useMemo } from "react"

const ApecPcocRow = ({ rowInfo }) => {
  const {
    sites, 'sites-other': sitesOther, betx, 'hydrocarbons-f1-f4': hydrocarbons, 'poly-aromatic-hydrocarbons': pahs,
    salinity, metals, other
  } = useMemo(() => rowInfo, [rowInfo])

  return (
    <tr>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center'>
          { sitesOther || sites }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center font-bold'>
          { betx === 'true' ? "X" : "" }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center font-bold'>
          { pahs === 'true' ? "X" : "" }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center font-bold'>
          { hydrocarbons === 'true' ? "X" : "" }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center font-bold'>
          { metals === 'true' ? "X" : "" }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center font-bold'>
          { salinity === 'true' ? "X" : "" }
        </p>
      </td>
      <td className='border border-gray-400 py-4 px-8'>
        <p className='w-full text-center'>
          { other }
        </p>
      </td>
    </tr>
  )
}

export default ApecPcocRow
