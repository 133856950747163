import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ChecklistProvider } from '../contexts/checklistContext'
import { DocumentBuilderProvider } from "../contexts/documentBuilderContext"
import { DocumentLogProvider } from "../contexts/documentLogContext"
import { NoteFilterProvider } from "../contexts/noteFilterContext"
import { PhotoProvider } from '../contexts/photoContext'
import { PortfolioProvider } from "../contexts/portfolioContext"
import Project from "../contexts/projectContext"
import { ReportProvider } from "../contexts/reportContext"
import { TrackerProvider } from "../contexts/trackerContext"
import { UserProvider } from '../contexts/userContext'
import AiProjectDocument from './ai/projectDocuments'
import Checklist from './checklists'
import DocumentBuilder from "./documentBuilder"
import EditDocumentBuilder from "./documentBuilder/edit"
import DocumentLog from "./documentLog"
import HistoricalAssessment from "./historicalAssessment"
import LabAnalytics from './labAnalytics'
import AnalyticSummary from './labAnalytics/analyticSummary'
import TestResults from './labAnalytics/testResults'
import PhotoGallery from './photoGallery'
import Export from "./projects/exportContext"
import Note from "./projects/note"
import Notes from "./projects/notes"
import BoreholeScatterChart from './projects/notes/charts/boreholeScatterChart'
import Permits from "./projects/permits"
import Permit from "./projects/permits/permit"
import PortfolioProject from "./projects/project"
import Report from "./reports"
import ReportsExport from "./reports/reports-export"
import SafetyTicket from './safetyTicket'
import LandingPage from "./shared/landingPage"
import TimesheetV2 from "./timesheetV2"
import Tracker from "./trackers"
import TrackerChangeLog from "./trackers/changeLog"
import TrainingTracker from "./trainingTracker"
import UserLandingPage from "./user"

export default function MainProject({ project, routes }) {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route
            path='/projects/:projectId/notes/:noteId'
            element={
              <Project project={ project } routes={ routes }>
                <NoteFilterProvider>
                  <Notes>
                    <Note/>
                  </Notes>
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/notes'
            element={
              <Project project={ project } routes={ routes }>
                <NoteFilterProvider>
                  <Notes/>
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/permits/:permitId'
            element={
              <Project project={ project } routes={ routes }>
                <Permit routes={ routes }/>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/permits'
            element={
              <Project project={ project } routes={ routes }>
                <Permits/>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/landing_page'
            element={
              <Export project={ project } routes={ routes }>
                <LandingPage project={ project } routes={ routes }/>
              </Export>
            }
          />
          <Route
            path='/projects/:projectId/historical-assessment'
            element={ <HistoricalAssessment project={ project }/> }/>
          <Route
            path='/projects/:projectId/trackers/:trackerSlug/change-log'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <TrackerProvider>
                    <TrackerChangeLog routes={ routes }/>
                  </TrackerProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/trackers/:trackerSlug'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <TrackerProvider>
                    <Tracker routes={ routes }/>
                  </TrackerProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/checklists/:checklistSlug'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <ChecklistProvider>
                    <Checklist routes={ routes }/>
                  </ChecklistProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/reports/:reportSlug'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <ReportProvider>
                    <Report routes={ routes }/>
                  </ReportProvider>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/reports-export'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <ReportsExport routes={ routes }/>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/document-log'
            element={
              <Project project={ project } routes={ routes }>
                <DocumentLogProvider>
                  <DocumentLog/>
                </DocumentLogProvider>
              </Project>
            }/>
          <Route
            path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId'
            element={
              <Project project={ project } routes={ routes }>
                <DocumentBuilderProvider>
                  <DocumentBuilder/>
                </DocumentBuilderProvider>
              </Project>
            }/>
          <Route
            path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId/edit'
            element={
              <Project project={ project } routes={ routes }>
                <DocumentBuilderProvider>
                  <EditDocumentBuilder/>
                </DocumentBuilderProvider>
              </Project>
            }/>
          <Route
            path='/projects/:projectId'
            element={
              <Project project={ project } routes={ routes }>
                <NoteFilterProvider>
                  <PortfolioProject project={ project } routes={ routes }/>
                </NoteFilterProvider>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/borehole_depth_chart'
            element={
              <Project project={ project } routes={ routes }>
                <BoreholeScatterChart routes={ routes }/>
              </Project>
            }
          />
          <Route
            path='/projects/:projectId/photo_gallery'
            element={
              <PhotoProvider>
                <PortfolioProvider>
                  <PhotoGallery/>
                </PortfolioProvider>
              </PhotoProvider>
            }
          />
          <Route
            path='/projects/:projectId/ai/documents'
            element={
              <Project project={ project } routes={ routes }>
                <PortfolioProvider>
                  <AiProjectDocument routes={ routes }/>
                </PortfolioProvider>
              </Project>
            }
          />
          <Route
            path='projects/:projectId/sample-collections'
            element={ <LabAnalytics project={ project } routes={ routes }/> }
          />
          <Route
            path='projects/:projectId/analytic-exports/:analyticExportId'
            element={ <TestResults project={ project } routes={ routes }/> }
          />
          <Route
            path='projects/:projectId/analytic-exports/:analyticExportId/summary'
            element={ <AnalyticSummary project={ project } routes={ routes }/> }
          />
          <Route path='/timesheets' element={ <TimesheetV2/> }/>
          <Route path='/timesheets_v2' element={ <TimesheetV2/> }/>
          <Route path='/users/landing_page' element={ <UserLandingPage/> }/>
          <Route path='/safety_tickets' element={ <SafetyTicket/> }/>
          <Route path='/training-tracker' element={ <TrainingTracker/> }/>
        </Routes>
      </UserProvider>
    </Router>
  )
}
