import React from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'
import TimeTableBody from './body'
import TimeTableHeader from './header'
import TimesheetNewEntryButton from './newEntryButton'

const TimesheetTable = () => {
  return (
    <>
      <table className={tableStyles.table + " min-w-full"}>
        <TimeTableHeader  />
        <TimeTableBody />
      </table>
      <div className="flex justify-between">
        <TimesheetNewEntryButton />
      </div>
    </>
  )
}

export default TimesheetTable
