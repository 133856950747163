import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { createDayEntry, createNewTimeEntry, deleteTimesheetTimeEntry, updateDayEntryAPI, updateTimeEntryAPI } from "../api/timesheetsApi"
import { apiVerifierV2, removeTimeEntryWithId, updateDayEntry, updateTimeEntryValue, updateTimeEntryWithId } from "../utilities/timesheetHelpers"

/* Actions */
const ACTIONS = {
  INIT: "INIT",
  INIT_EDIT_ENTRY_STATE: "INIT_EDIT_ENTRY_STATE",
  TOGGLE: "TOGGLE",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_EDIT_ENTRY_STATE: "UPDATE_EDIT_ENTRY_STATE",
  ADD_DAY_ENTRY: "ADD_DAY_ENTRY",
  UPDATE_DAY_ENTRY: "UPDATE_DAY_ENTRY",
  ADD_TIME_ENTRY: "ADD_TIME_ENTRY",
  UPDATE_TIME_ENTRY: "UPDATE_TIME_ENTRY",
  REMOVE_TIME_ENTRY: "REMOVE_TIME_ENTRY",
}

/* Initial States */
export const blankEditEntry = {
  id: null,
  description: "",
  portfolio_id: null,
  project_id: null,
  timesheet_task_id: null,
  day_entry_id: null,
  date: null,
  hours: 0,
  errors: false,
  selectedDay: null,
}

const initialState = {
  week: {},
  userId: null,
  editModal: false,
  editEntry: blankEditEntry,
}

const initState = (week) => ({ ...initialState, week: week, userId: week.user_id })

const initEditEntryState = (
  { id, description, project_id, portfolio_id, timesheet_task_id, day_entry_id, date, selectedDay, hours, user_id }
) => ({
  id: id,
  user_id: user_id,
  description: description || "",
  project_id: project_id,
  portfolio_id: portfolio_id,
  timesheet_task_id: timesheet_task_id,
  day_entry_id: day_entry_id || "",
  date: date || "",
  selectedDay: selectedDay || null,
  hours: hours || 0,
  errors: false,
})

/* Reducer */
const timeTableReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return { ...state, ...initState(action.initState) }
    case ACTIONS.INIT_EDIT_ENTRY_STATE:
      return { ...state, editEntry: initEditEntryState(action.initEntryState) }
    case ACTIONS.TOGGLE:
      return { ...state, [action.state]: !state[action.state] }
    case ACTIONS.UPDATE_TIME_ENTRY:
      const updatedWeekUpdatedTimeEntry = updateTimeEntryWithId(state.week, action.timeEntry)
      return { ...state, week: updatedWeekUpdatedTimeEntry }
    case ACTIONS.REMOVE_TIME_ENTRY:
      const updatedWeekRemoveTimeEntry = removeTimeEntryWithId(state.week, action.dayEntryId, action.timeEntryId)
      return { ...state, week: updatedWeekRemoveTimeEntry }
    case ACTIONS.ADD_DAY_ENTRY:
      const updatedWeekDayEntry = updateDayEntry(state.week, action.dayEntry)
      return { ...state, week: updatedWeekDayEntry }
    case ACTIONS.ADD_TIME_ENTRY:
      const updatedWeekTimeEntry = updateTimeEntryValue(state.week, action.dayEntry, action.timeEntry)
      return { ...state, week: updatedWeekTimeEntry }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_EDIT_ENTRY_STATE:
      return { ...state, editEntry: { ...state.editEntry, [action.field]: action.value } }
    case ACTIONS.UPDATE_DAY_ENTRY:
      const updatedWeekDayEntryValue = updateDayEntry(state.week, action.dayEntry)
      return { ...state, week: updatedWeekDayEntryValue }
    default:
      return state
  }
}

/* Contexts */
const TimeTableContextV2 = createContext(initialState)
const TimeTableApiContextV2 = createContext({})

/* Providers */
export const TimeTableProviderV2 = ({ children, week }) => {
  const [state, dispatch] = useReducer(timeTableReducer, week, initState)
  useEffect(() => void dispatch({ type: ACTIONS.INIT, initState: week }), [week])

  const api = useMemo(() => {
    const toggleState = (state) => dispatch({ type: ACTIONS.TOGGLE, state })

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateEditEntryState = (field, value) => dispatch({ type: ACTIONS.UPDATE_EDIT_ENTRY_STATE, field, value })

    const addDayEntry = dayEntry => dispatch({ type: ACTIONS.ADD_DAY_ENTRY, dayEntry })

    const addTimeEntry = (dayEntry, timeEntry) => dispatch({ type: ACTIONS.ADD_TIME_ENTRY, timeEntry, dayEntry })

    const initEditModal = entry => dispatch({ type: ACTIONS.INIT_EDIT_ENTRY_STATE, initEntryState: entry }) || toggleState('editModal')

    const resetModal = (modalType) => toggleState(modalType) || updateState('editEntry', blankEditEntry)

    const createTimeEntry = async (formRef) => {
      const formData = apiVerifierV2(formRef)
      if (formData) {
        const dayEntry = await createDayEntry(formData)
        addDayEntry(dayEntry)
        const timeEntry = await createNewTimeEntry(dayEntry.id, formData)
        addTimeEntry(dayEntry, timeEntry)
        resetModal('editModal')
      } else {
        updateEditEntryState('errors', true)
      }
    }

    const updateTimeEntry = formRef => {
      const formData = apiVerifierV2(formRef)
      if (formData) {
        updateTimeEntryAPI(formData.get('day_entry_id'), formData.get('id'), formData)
          .then(response => dispatch({ type: ACTIONS.UPDATE_TIME_ENTRY, timeEntry: response }) || resetModal('editModal'))
      } else {
        updateEditEntryState('errors', true)
      }
    }

    const deleteTimeEntry = (dayEntryId, timeEntryId) => deleteTimesheetTimeEntry(dayEntryId, timeEntryId)
      .then(() => dispatch({ type: ACTIONS.REMOVE_TIME_ENTRY, dayEntryId, timeEntryId }) || resetModal('editModal'))

    const updateSelectedUsers = value => updateState('selectedUsers', value)

    return {
      toggleState, updateState, updateEditEntryState, initEditModal,
      updateTimeEntry, createTimeEntry, deleteTimeEntry, updateSelectedUsers
    }
  }, [])

  api.updateDayEntry = async(dayEntryId, params) => {updateDayEntryAPI(dayEntryId, params).then(res => dispatch({ type: ACTIONS.UPDATE_DAY_ENTRY, dayEntry: res }))}


  return (
    <TimeTableApiContextV2.Provider value={api}>
      <TimeTableContextV2.Provider value={state}>
        {children}
      </TimeTableContextV2.Provider>
    </TimeTableApiContextV2.Provider>
  )
}

/* Custom Context Hooks */
export const useTimeTableContextV2 = () => useContext(TimeTableContextV2)
export const useTimeTableApiV2 = () => useContext(TimeTableApiContextV2)
