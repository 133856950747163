import React from 'react'
import { daysOfWeek } from '../../../constants'
import { useTimeTableContextV2 } from '../../../contexts/timeTableContextV2'
import TimeTableEntryRow from './entryRow'
import TimesheetTableTotalRow from "./totalRow"

const TimeTableBody = () => {
  const { week } = useTimeTableContextV2()
  return (
    <tbody>
    { daysOfWeek.map((day) => (
      <React.Fragment key={ day }>
        { week[day]?.time_entries && week[day].time_entries.map((entry, entryIndex) => (
          <TimeTableEntryRow key={ `${ day }-${ entryIndex }` } entry={ entry } entryDay={ day }/>
        )) }
      </React.Fragment>
    )) }
    <TimesheetTableTotalRow/>
    </tbody>
  )
}

export default TimeTableBody
