import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { deleteTimesheetEntry, postTimesheetEntry, updateTimesheetEntry } from "../api/timesheetsApi"
import { dateWithinRange, stringToUTCDate } from "../utilities/dateHelpers"
import { apiVerifier } from "../utilities/timesheetHelpers"
import { useTimesheetContext } from "./timesheetContext"

/* Actions */
const ACTIONS = {
  INIT: "INIT",
  INIT_ENTRY_STATE: "INIT_ENTRY_STATE",
  TOGGLE: "TOGGLE",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_EDIT_ENTRY_STATE: "UPDATE_EDIT_ENTRY_STATE",
  ADD_ENTRY: "ADD_ENTRY",
  UPDATE_ENTRY: "UPDATE_ENTRY",
  REMOVE_ENTRY: "REMOVE_ENTRY",
}

/* Initial States */
export const blankEditEntry = {
  id: null,
  summary: "",
  project_id: null,
  timesheet_task_id: null,
  week_start: null,
  sunday: "",
  monday: "",
  tuesday: "",
  wednesday: "",
  thursday: "",
  friday: "",
  saturday: "",
  errors: false,
}

const initialState = {
  entries: [],
  currentWeeksEntries: [],
  userId: null,
  editModal: false,
  editEntry: blankEditEntry,
}

const initState = ({ entries, userId }) => ({ ...initialState, entries: entries, userId: userId })

const initEditEntryState = (
  { id, summary, project_id, portfolio_id, timesheet_task_id, week_start, sunday, monday, tuesday, wednesday, thursday, friday, saturday }
) => ({
  id: id,
  summary: summary || "",
  project_id: project_id,
  portfolio_id: portfolio_id,
  timesheet_task_id: timesheet_task_id,
  week_start: week_start,
  sunday: sunday || "",
  monday: monday || "",
  tuesday: tuesday || "",
  wednesday: wednesday || "",
  thursday: thursday || "",
  friday: friday || "",
  saturday: saturday || "",
  errors: false,
})

/* Reducer */
const timeTableReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return { ...state, ...initState(action.initState) }
    case ACTIONS.INIT_ENTRY_STATE:
      return { ...state, editEntry: initEditEntryState(action.initEntryState) }
    case ACTIONS.TOGGLE:
      return { ...state, [action.state]: !state[action.state] }
    case ACTIONS.ADD_ENTRY:
      return { ...state, entries: [...state.entries, action.value] }
    case ACTIONS.UPDATE_ENTRY:
      return { ...state, entries: state.entries.map(entry => entry.id === action.value.id ? action.value : entry) }
    case ACTIONS.REMOVE_ENTRY:
      return { ...state, entries: state.entries.filter(entry => entry.id !== action.entryId) }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_EDIT_ENTRY_STATE:
      return { ...state, editEntry: { ...state.editEntry, [action.field]: action.value } }
    default:
      return state
  }
}

/* Contexts */
const TimeTableContext = createContext(initialState)
const TimeTableApiContext = createContext({})

/* Providers */
export const TimeTableProvider = ({ children, entries, userId }) => {
  const { weekStart, weekEnd } = useTimesheetContext()
  
  const [state, dispatch] = useReducer(timeTableReducer, { entries: entries, userId: userId }, initState)

  const api = useMemo(() => {
    const toggleState = (state = 'editModal') => dispatch({ type: ACTIONS.TOGGLE, state })

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateEditEntryState = (field, value) => dispatch({ type: ACTIONS.UPDATE_EDIT_ENTRY_STATE, field, value })

    const addEntry = value => dispatch({ type: ACTIONS.ADD_ENTRY, value })

    const initEditModal = entry => dispatch({ type: ACTIONS.INIT_ENTRY_STATE, initEntryState: entry }) || toggleState()

    const resetModal = () => toggleState() || updateState('editEntry', blankEditEntry)

    const updateEntry = formRef => {
      const formData = apiVerifier(formRef)
      if (formData) {
        updateTimesheetEntry(formData.get('id'), formData)
          .then(response => dispatch({ type: ACTIONS.UPDATE_ENTRY, value: response }) || resetModal())
      } else {
        updateEditEntryState('errors', true)
      }
    }

    const createEntry = formRef => {
      const formData = apiVerifier(formRef)
      if (formData) {
        postTimesheetEntry(formData).then(response => addEntry(response) || resetModal())
      } else {
        updateEditEntryState('errors', true)
      }
    }

    const deleteEntry = entryId => deleteTimesheetEntry(entryId)
      .then(() => dispatch({ type: ACTIONS.REMOVE_ENTRY, entryId }) || resetModal())

    const updateSelectedUsers = value => updateState('selectedUsers', value)

    return {
      toggleState, updateState, updateEditEntryState, addEntry, initEditModal,
      updateEntry, createEntry, deleteEntry, updateSelectedUsers
    }
  }, [])

  // Determine current weeks entries by weekStart
  useEffect(() => {
    const currentEntries = state.entries.filter(entry => {
      return dateWithinRange(stringToUTCDate(entry.week_start), weekStart, weekEnd)
    })

    api.updateState('currentWeeksEntries', currentEntries)
  }, [state.entries, weekStart, weekEnd])

  return (
    <TimeTableApiContext.Provider value={api}>
      <TimeTableContext.Provider value={state}>
        {children}
      </TimeTableContext.Provider>
    </TimeTableApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useTimeTableContext = () => useContext(TimeTableContext)
export const useTimeTableApi = () => useContext(TimeTableApiContext)
