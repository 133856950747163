import React, { useMemo } from "react"
import { timeSheetHeaders } from "../../../constants/headers"
import { useTimesheetContextV2 } from "../../../contexts/timesheetContextV2"
import { useTimeTableContextV2 } from "../../../contexts/timeTableContextV2"
import { addDays, formatDateString } from "../../../utilities/dateHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { projectManagerOrGreater } from "../../../utilities/userHelpers"
import { classNames } from "../../../utilities/utils"
import ApprovalHeader from "./approvalHeader"

const smallHeaderStyles = classNames(tableStyles.th, tableStyles.thSm)

const TimeTableHeader = () => {
  const { weekStart, user } = useTimesheetContextV2()
  const { week } = useTimeTableContextV2()
  const weekDates = useMemo(() => { return Array.from({ length: 7 }, (_, i) => addDays(weekStart, i)) }, [weekStart])
  const showApproval = projectManagerOrGreater(user) && (user.id !== week.user_id) || !projectManagerOrGreater(user)
  return (
    <thead className={`${tableStyles.tableHeaderRow} top-[65px]`}>
      {showApproval && <ApprovalHeader headerLength={timeSheetHeaders.length} week={week} />}
      <tr className={`${tableStyles.tableHeaderRow} top-[65px]`}>
        {timeSheetHeaders.map((header, index) => (
          <th className={smallHeaderStyles} key={index}>
            <div className={tableStyles.thContent}>
              {header.title}
            </div>
          </th>
        ))}
        {weekDates.map((date, index) => (
          <th className={smallHeaderStyles} key={index}>
            <div className={tableStyles.thContent}>
              {formatDateString(date, { weekday: 'long', month: 'long' })}
            </div>
          </th>
        ))}
        <th className={classNames(tableStyles.th, tableStyles.thXl)}>
          <div className={tableStyles.thContent}>
            Summary of Activities
          </div>
        </th>
      </tr>
    </thead>
  )
}

export default TimeTableHeader
