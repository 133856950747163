import React, { useEffect, useRef, useState } from "react"
import { tableStyles } from "./historicalAssessmentHelper"
import ApecPcocRow from "./apecPcocRow"
import { notifyAlert, notifyError } from "../shared/notice";
import { updateHistoricalAssessment } from "../../api/historicalAssessmentApi"
import { useHistoricalAssessmentAPI, useHistoricalAssessmentContext } from "../../contexts/historicalAssessment"
import { getTracker } from "../../api/trackers"
import TooltipButton from "../shared/tooltipButton"

const AssessmentForm = () => {
  const { project, assessmentRows } = useHistoricalAssessmentContext()
  const { addBlankRow } = useHistoricalAssessmentAPI()
  const formRef = useRef(null)
  const [historicalTracker, setHistoricalTracker] = useState()
  const [interviewTracker, setInterviewTracker] = useState()

  useEffect(() => {
    getTracker(`/projects/${ project.id }/trackers`)
      .then(response => {
        setHistoricalTracker(response.historical_tracker)
        setInterviewTracker(response.interview_tracker)
      })
  }, [project])

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(formRef.current)

    updateHistoricalAssessment(project.id, formData)
      .then(() => notifyAlert('Historical Assessment Data Saved'))
      .catch(err => notifyError(err))
  }

  return (
    <form ref={ formRef } onSubmit={ handleSubmit }
          className="flex flex-col w-full py-2 px-2 border-t border-gray-200 relative">
      <div className='flex flex-row justify-between items-center'>
        <h2 className="text-2xl font-bold">APEC/PCOC</h2>
        <div className="flex">
          <TooltipButton
            className="btn btn-primary font-semibold mt-6 relative cursor-pointer z-0 max-w-max mr-6"
            buttonText="Interview Log"
            tooltipText="Please contact an admin to enable this feature"
            href={ interviewTracker && `/projects/${ project.id }/trackers/${ interviewTracker?.slug }` }
          />
          <TooltipButton
            className="btn btn-primary font-semibold mt-6 relative cursor-pointer z-0 max-w-max mr-6"
            buttonText="Historical Document Tracker"
            tooltipText="Please contact an admin to enable this feature"
            href={ historicalTracker && `/projects/${ project.id }/trackers/${ historicalTracker?.slug }` }
          />
          <TooltipButton
            className="btn btn-primary font-semibold mt-6 relative cursor-pointer z-0 max-w-max mr-6"
            buttonText="Stage 1 Checklist"
            tooltipText="Please contact an admin to enable this feature"
            href={ project.priority_checklist_slug && `/projects/${ project.id }/checklists/${ project?.priority_checklist_slug }` }
          />
          <button
            className="btn btn-primary font-semibold mt-6 relative cursor-pointer z-0 max-w-max"
            id="submit-btn"
            name="submit-btn"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
      <div className='overflow-x-scroll m-0 p-0'>
        <table className={ tableStyles.table }>
          <thead>
          <tr>
            <th className={ tableStyles.th + ' sticky bg-white left-0 w-72 z-50' }>APEC</th>
            <th className={ tableStyles.th }>BETX</th>
            <th className={ tableStyles.th }>Hydrocarbons (F1 - F4) / EPH</th>
            <th className={ tableStyles.th }>Poly-Aromatic Hydrocarbons</th>
            <th className={ tableStyles.th }>Salinity</th>
            <th className={ tableStyles.th }>Metals</th>
            <th className={ tableStyles.th }>Est. # of Boreholes</th>
            <th className={ tableStyles.th }>Min Depth</th>
            <th className={ tableStyles.th }>Max Depth</th>
            <th className={ tableStyles.th }>If Other (please enter)</th>
          </tr>
          </thead>
          <tbody>
          { assessmentRows.map((row, index) => <ApecPcocRow key={ `row-${ index }` } rowIndex={ index }
                                                            rowInfo={ row }/>) }
          </tbody>
        </table>
      </div>
      <button
        className="btn btn-primary font-semibold mt-4 relative cursor-pointer z-0 max-w-max"
        id="add-row-btn"
        name="add-row-btn"
        type="button"
        onClick={ addBlankRow }
      >
        + Add New Row
      </button>
    </form>
  )
}

export default AssessmentForm
