import React, { Fragment } from "react"
import { useTimesheetContext } from "../../contexts/timesheetContext"
import { TimeTableProvider } from "../../contexts/timeTableContext"
import { getUsername } from "../../utilities/timesheetHelpers"
import TimesheetEntryModal from "./modal"
import TimesheetTable from "./table"

const UserTable = () => {
  const { users, timeTables, user } = useTimesheetContext()
  const totalUsers = !user.empty ? [ ...users, [user.id, user.human_identifier]] : users

  const renderTimesheet = (userId) => (
    <Fragment key={userId}>
      <div className="flex gap-8 items-center">
        <h1 className="text-3xl font-bold">{getUsername(totalUsers, userId)}'s Timesheet</h1>
      </div>
      <TimeTableProvider entries={timeTables[userId]} userId={userId}>
        <div className="w-full">
          <TimesheetTable />
        </div>
        <TimesheetEntryModal />
      </TimeTableProvider>
    </Fragment>
  )

  return (
    <>
      {user.id && renderTimesheet(user.id)}
      {Object.keys(timeTables).map((userId) => (
        parseInt(userId) !== user.id && renderTimesheet(userId)
      ))}
    </>
  )
}

export default UserTable
