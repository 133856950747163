import React, { useEffect, useMemo, useState } from 'react'
import { simpleGet } from "../../api/apiConfig"
import { classNames } from "../../utilities/utils"
import { tableStyles } from "../../utilities/stylesHelpers"

const TrainingTracker = () => {
  const [headers, setHeaders] = useState([])

  // Map over the headers and push entries into an object WITH THE HEADER ID
  const entriesCollection = useMemo(() => {
    const collectionResult = []
    headers.map(header => {
      header.entries.map((entry, index) => {
        const oldObject = collectionResult[index] ? collectionResult[index] : {}
        collectionResult[index] = { ...oldObject, [header.id]: entry }
      })
    })

    return collectionResult
  }, [headers])

  useEffect(() => void simpleGet('/training-tracker.json').then(res => setHeaders(res)), [])

  return (
    <div className="p-4">
      <h1 className="font-bold text-4xl">Training Tracker </h1>
      <table className={ classNames(tableStyles.table, "w-full") }>
        <thead className={ tableStyles.tableHeaderRow }>
        <tr className={ tableStyles.tableHeaderRow }>
          { headers?.map(header => (
            <th key={ header.id } className={ classNames(tableStyles.th, "text-lg") }>{ header?.title }</th>
          )) }
        </tr>
        </thead>
        <tbody>
        { entriesCollection.map((entryRow, index) => (
          <tr key={ index }>
            { headers?.map(header => (
              <td className={ tableStyles.td } key={ header.id }>
                <div dangerouslySetInnerHTML={ { __html: entryRow[header.id]?.value } }/>
              </td>
            )) }
          </tr>
        )) }
        </tbody>
      </table>
    </div>
  )
}

export default TrainingTracker
