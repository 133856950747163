/**
 * Maps an array of projects to an array with a label of the projects name and a value of the projects url
 * @param input
 * @returns {*}
 */
export const mapProjectToLabelValue = (pathEnd = '') => (projects) => projects.map(project => ({ label: `${project.job_number} - (${project.name})`, value: `/projects/${project.id}${pathEnd}` }))
export const mapTrackerToLabelValue = (scope, scopeId) => (trackers) => trackers.map(tracker => ({ label: tracker.name, value: `/${scope}/${scopeId}/trackers/${tracker.slug}` }))
export const mapChecklistToLabelValue = (scope, scopeId) => (checklists) => checklists.map(checklist => ({ label: checklist.name, value: `/${scope}/${scopeId}/checklists/${checklist.slug}` }))
export const mapReportToLabelValue = (scope, scopeId) => (reports) => reports.map(report => ({ label: report.name, value: `/${scope}/${scopeId}/reports/${report.slug}` }))

/**
 * Factory function that returns a function which filters an array of projects depending on if they have 'tracker' in their name.
 * isTracker
 * @param isTracker Bool - determines if the filter should return projects with the 'tracker' string in their name.
 * True returns the tracker projects, false returns the non-tracker projects
 * @param projects Array - the array of projects to filter
 * @returns {function(*): *}
 */
export const filterTrackerString = (isTracker = true) => (projects) => projects.filter(element => element.name.toLowerCase().includes('tracker') === isTracker)
