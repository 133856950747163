import API, { simpleGet, simplePatch, simplePost, simplePut } from "./apiConfig"
const csrfToken = document.querySelector('[name=csrf-token]').content

export const getTimesheet = () => simpleGet('/timesheets.json')

export const getTimesheetV2 = () => simpleGet('/timesheets_v2.json')

export const postTimesheetEntry = formData => simplePost('/timesheet_entries', formData)

export const createNewTimeEntry = (dayEntryId, formData) => simplePost(`/timesheet/day_entries/${dayEntryId}/time_entries`, formData)

export const createDayEntry = (formData) => simplePost('/timesheet/day_entries', formData)

export const updateTimesheetEntry = (entryId, formData) => simplePut(`/timesheet_entries/${entryId}`, formData)

export const updateTimeEntryAPI = (dayEntryId, entryId, formData) => simplePatch(`/timesheet/day_entries/${dayEntryId}/time_entries/${entryId}`, formData)

export const getTimesheetEntries = (userIds) => simpleGet('/timesheet_entries.json', { params: { users_ids: userIds } })

export const getWeekOfDayEntries = (date, userIds) => simpleGet('/timesheet/day_entries/get_week.json', { params: { date: date, user_ids: userIds } })

export const updateDayEntryAPI = (dayEntryId, params) => simplePatch(`/timesheet/day_entries/${dayEntryId}`, params) 

export const deleteTimesheetEntry = entryId => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`/timesheet_entries/${entryId}`)
}

export const deleteTimesheetTimeEntry = (dayEntryId,timeEntryId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`/timesheet/day_entries/${dayEntryId}/time_entries/${timeEntryId}`)
}
