import React from "react"
import { TimesheetProviderV2 } from "../../contexts/timesheetContextV2"
import TimesheetBreadcrumbs from "./breadcrumbs"
import TimesheetFilters from "./filters"
import UserSelect from "./userSelect"
import UserTable from "./userTable"

const Timesheet = () => {
  return (
    <TimesheetProviderV2>
      <div className="p-8 flex flex-col gap-8 h-full w-full overflow-auto">
        <TimesheetBreadcrumbs />
        <TimesheetFilters />
        <UserTable />
        <UserSelect />
      </div>
    </TimesheetProviderV2>
  )
}

export default Timesheet;
