import React from 'react'
import { HistoricalAssessmentProvider } from "../../contexts/historicalAssessment"
import AssessmentTable from "./assessmentTable"

const SimpleHistoricalAssessment = ({ project }) => {
  return (
    <HistoricalAssessmentProvider project={ project }>
      <AssessmentTable/>
    </HistoricalAssessmentProvider>
  )
}

export default SimpleHistoricalAssessment
