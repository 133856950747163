import React, { useMemo } from "react"
import { timeSheetHeaders } from "../../../constants/headers"
import { useTimesheetContext } from "../../../contexts/timesheetContext"
import { addDays, formatDateString } from "../../../utilities/dateHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { classNames } from "../../../utilities/utils"

const smallHeaderStyles = classNames(tableStyles.th, tableStyles.thSm)

const TimesheetTableHeader = () => {
  const { weekStart } = useTimesheetContext()
  const weekDates = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => addDays(weekStart, i))
  }, [weekStart])

  return (
    <thead className={`${tableStyles.tableHeaderRow} top-[65px]`}>
      <tr className={`${tableStyles.tableHeaderRow} top-[65px]`}>
        {timeSheetHeaders.map((header, index) => (
          <th className={smallHeaderStyles} key={index}>
            <div className={tableStyles.thContent}>
              {header.title}
            </div>
          </th>
        ))}
        {weekDates.map((date, index) => (
          <th className={smallHeaderStyles} key={index}>
            <div className={tableStyles.thContent}>
              {formatDateString(date, { weekday: 'long', month: 'long' })}
            </div>
          </th>
        ))}
        <th className={classNames(tableStyles.th, tableStyles.thXl)}>
          <div className={tableStyles.thContent}>
            Summary of Activities
          </div>
        </th>
      </tr>
    </thead>
  )
}

export default TimesheetTableHeader
