import React from "react"
import { blankEditEntry, useTimeTableApiV2 } from "../../../contexts/timeTableContextV2"
import StandardButton from "../../shared/standardButton"

const TimesheetNewEntryButton = () => {
  const { toggleState, updateState } = useTimeTableApiV2()
  const onClickHandler = () => updateState('editEntry', blankEditEntry) || toggleState('editModal')

  return <StandardButton buttonText="Add New Entry" onClickHandler={onClickHandler} />
}

export default TimesheetNewEntryButton
