import React from 'react'
import Content from '../content'
import { useProjectContext } from "../../../contexts/projectContext"
import SimpleHistoricalAssessment from "../../simpleHistoricalAssessment"

const DefaultLayout = ({ sectionLayout, ContentComponent = Content }) => {
  const { project } = useProjectContext()

  return (
    <div className="max-w-[978px]">
      <SimpleHistoricalAssessment project={ project }/>
    </div>
  )
}

export default DefaultLayout
