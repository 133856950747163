import React from 'react'
import { useHistoricalAssessmentContext } from "../../contexts/historicalAssessment"
import { tableStyles } from "../historicalAssessment/historicalAssessmentHelper"
import ApecPcocRow from "./apecPcocRow"

const AssessmentTable = () => {
  const { assessmentRows } = useHistoricalAssessmentContext()

  return (
    <table className={ tableStyles.table }>
      <thead>
      <tr>
        <th className={ tableStyles.th }>APEC</th>
        <th className={ tableStyles.th }>BETX/VPH</th>
        <th className={ tableStyles.th }>PAHS</th>
        <th className={ tableStyles.th }>EPH</th>
        <th className={ tableStyles.th }>Metals</th>
        <th className={ tableStyles.th }>Salinity</th>
        <th className={ tableStyles.th }>Other</th>
      </tr>
      </thead>
      <tbody>
      { assessmentRows.map((row, index) => <ApecPcocRow key={ index } rowInfo={ row }/>) }
      </tbody>
    </table>
  )
}

export default AssessmentTable
