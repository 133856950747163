import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { useParams } from "react-router-dom"
import {
  createCsvVersion,
  getBasicSummary,
  getDocument,
  getStatuses,
  updateDocument,
  updateTextractTable,
  uploadDocumentToOpenai
} from "../api/aiDocumentsApi"
import { notifyError } from "../components/shared/notice"

/* Actions */
const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  UPDATE_TABLE: 'UPDATE_TABLE',
}

/* Initial States */
const initialState = {
  document: {},
  modals: {},
  statuses: [],
  loading: {}
}

const aiDocumentReducer = (state, action) => {
  switch (action?.type) {
    case ACTIONS.UPDATE_STATE:
      delete action.type
      return { ...state, ...action }
    case ACTIONS.UPDATE_DOCUMENT:
      return { ...state, document: { ...state.document, [action.field]: action.value } }
    case ACTIONS.UPDATE_TABLE:
      const updatedTables = state.document.tables.map(table => (table.id === action.table.id) ? action.table : table)
      return { ...state, document: { ...state.document, tables: updatedTables } }
    case ACTIONS.TOGGLE_MODAL:
      return { ...state, modals: { ...state.modals, [action.modalType]: !state.modals[action.modalType] } }
    case ACTIONS.TOGGLE_LOADING:
      return { ...state, loading: { ...state.loading, [action.loadingType]: !state.loading[action.loadingType] } }
    default:
      return state
  }
}

/* Contexts */
const AiDocumentContext = createContext(initialState)
const AiDocumentApiContext = createContext({
  updateState: () => {},
  updateDocumentState: () => {},
  toggleModal: () => {},
  updateJson: () => {},
  generateTables: () => {},
  uploadToOpenAi: () => {},
  onGetBasicSummary: () => {},
  updateStatus: () => {},
})

/* Providers */
export const AiDocumentProvider = ({ children }) => {
  const { documentId } = useParams()
  const [state, dispatch] = useReducer(aiDocumentReducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, [field]: value })
    const updateDocumentState = (field, value) => dispatch({ type: ACTIONS.UPDATE_DOCUMENT, field, value })
    const toggleModal = (modalType) => dispatch({ type: ACTIONS.TOGGLE_MODAL, modalType })
    const toggleLoading = (loadingType) => dispatch({ type: ACTIONS.TOGGLE_LOADING, loadingType })

    const updateJson = (body) => {
      updateDocument(documentId, body)
        .then(documentRes => api.updateState('document', documentRes[0]))
        .catch(() => notifyError('Failed to update documents'))
    }

    const generateTables = () => {
      api.toggleLoading('tables')
      createCsvVersion([documentId])
        .then(res => api.updateDocumentState('tables', res[0].tables))
        .then(() => api.toggleLoading('tables'))
    }

    const uploadToOpenAi = () => {
      uploadDocumentToOpenai(documentId)
        .then(res => api.updateDocumentState('openai_id', res.openai_id))
        .catch((res) => notifyError(res.response.data.errors))
    }

    const onGetBasicSummary = () => {
      getBasicSummary(documentId)
        .then(res => api.updateDocumentState('summary', res.summary))
        .catch((res) => notifyError(res.response.data.errors))
    }

    const updateStatus = (status) => {
      updateDocument(documentId, { status: status })
        .then(() => api.updateDocumentState('status', status))
        .catch(() => notifyError('Failed to update documents'))
    }

    const updateTable = (tableId, body) => {
      return updateTextractTable(tableId, body).then(res => dispatch({ type: ACTIONS.UPDATE_TABLE, table: res }))
    }

    return {
      updateState, updateDocumentState, toggleModal, toggleLoading, updateJson, generateTables, uploadToOpenAi,
      onGetBasicSummary, updateStatus, updateTable
    }
  }, [documentId])

  useEffect(() => {
    getDocument(documentId).then(docRes => api.updateState('document', docRes))
    getStatuses().then(statuses => api.updateState('statuses', statuses))
  }, [])

  return (
    <AiDocumentContext.Provider value={ state }>
      <AiDocumentApiContext.Provider value={ api }>
        { children }
      </AiDocumentApiContext.Provider>
    </AiDocumentContext.Provider>
  )
}

/* Hooks */
export const useAiDocumentContext = () => useContext(AiDocumentContext)
export const useAiDocumentAPI = () => useContext(AiDocumentApiContext)
